import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as RightArrowIcon } from '@a/icons/right-arrow.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
import { MobNav } from './MobNavD';
import { navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.gray1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
    transition: border-color 0.3s ease-in-out;

    > div {
        ${flexSpace};
        max-width: 1825px;
        margin: 0 auto;

        > div {
            ${flexCenter};
            justify-content: flex-end;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0 !important;
        border-bottom-width: 2px;
        padding-left: 48px;
    }

    @media (min-width: 1440px) {
        padding-left: 80px;
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            border-color: ${theme.colors.blue1};
        `};
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1280px) {
        display: block;
    }
`;

const menuStyles = css`
    align-items: stretch;

    > li {
        display: flex;
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }
    }
`;

const contentStyles = css`
    > ul {
        border-radius: 10px;
        border: 1px solid ${theme.colors.gray2};
        background-color: ${theme.colors.gray1};
        box-shadow:
            0px 4.006px 6.773px 0px rgba(109, 106, 93, 0.03),
            0px 8.278px 15.969px 0px rgba(109, 106, 93, 0.04),
            0px 18.532px 39.563px 0px rgba(109, 106, 93, 0.04),
            0px 51px 125px 0px rgba(109, 106, 93, 0.09);
    }
`;

const PhoneBtn = styled(CLink)<{ desktop?: boolean }>`
    ${flexCenter};
    margin-left: auto;
    margin-right: 32px;

    > svg {
        path {
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }

    @media (min-width: 1280px) {
        display: ${({ desktop }) => (desktop ? 'flex' : 'none')};
        padding: 34px 32px;
        transition: background-color 0.3s ease-in-out;
        border-left: 1px solid ${({ theme }) => theme.colors.gray3};
        margin: 0;

        :hover {
            background-color: ${({ theme }) => theme.colors.blue3};

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.blue1};
                }
            }
        }

        :focus-visible {
            background-color: ${({ theme }) => theme.colors.blue3};

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.blue1};
                }
            }
        }
    }
`;

export const HomeLogo = () => {
    return (
        <HomeLink to="/">
            <StaticImage
                src="../../../assets/images/layout/logo.png"
                alt="wall coating solutions"
                width={75}
                height={75}
                loading="eager"
                quality={100}
            />
        </HomeLink>
    );
};

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const Nav = () => {
    const [allDialogsClosed, setAllDialogsClosed] = useState(false);
    const closeAllDialogs = () => setAllDialogsClosed(open => !open);

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <HomeLogo />
                    <DesktopNavMenu
                        menuStyles={menuStyles}
                        contentStyles={contentStyles}
                        links={navLinks}
                    >
                        <NavItem>
                            <PhoneBtn
                                to={siteInfo.phone.link}
                                aria-label={`call us at ${siteInfo.phone.text}`}
                                desktop
                            >
                                <PhoneIcon />
                            </PhoneBtn>
                        </NavItem>
                        <NavItem>
                            <PrimaryBtn as={CLink} to="/contact-us/">
                                Contact Us <RightArrowIcon />
                            </PrimaryBtn>
                        </NavItem>
                    </DesktopNavMenu>

                    <PhoneBtn
                        to={siteInfo.phone.link}
                        aria-label={`call us at ${siteInfo.phone.text}`}
                    >
                        <PhoneIcon />
                    </PhoneBtn>

                    <MobNav allDialogsClosed={allDialogsClosed} closeAllDialogs={closeAllDialogs} />
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
